// WordGrid.scss

.word-grid {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background-color: rgb(30, 14, 14);
  overflow-y: auto;
}

.word-grid-choice {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: rgb(255,255,255);
background: -moz-linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
background: linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e41010",GradientType=1);
}
.word-grid-drag {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background-color: rgb(14, 30, 18);
  overflow-y: auto;
}
.word-grid-choice-drag {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: rgb(12, 177, 92);
  padding-bottom: 60rem;

}
// .selected-words-container {
//   margin-top: 20px;
  
//   h3 {
//     margin-bottom: 10px;
//   }

//   .selected-words {
//     display: flex;
//     gap: 20px;
//     background: rgb(255,255,255);
//     background: -moz-linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
//     background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
//     background: linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
//     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e41010",GradientType=1);
    
//     .selected-word {
//       width: 100px;
//       padding: 10px;
//       border: 1px solid #ccc;
//       text-align: center;
//       background-color: #f0f0f0; // Adjust as needed
//     }
//   }
// }

.selected-words-container {
  margin-top: 20px;
  
  h3 {
    margin-bottom: 10px;
  }

  .selected-words {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
    background: linear-gradient(90deg, rgba(255,255,255,0.756827731092437) 0%, rgba(228,16,16,1) 37%, rgba(228,16,16,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e41010",GradientType=1);
    
    .selected-word {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 150px; // Smaller size
      height: 150px;
      padding: 5px; // Reduced padding
      border: 1px solid #ccc;
      text-align: center;
      background-color: #f0f0f0;
      border-radius: 15%;
      border: #000000 2px solid;
      cursor: grabbing;
      
      img {
        width: 140px; // Smaller image size
        height: 100px;
        object-fit: cover; // Ensure image fits within the button
      }

      span {
        margin-top: 5px;
        font-size: 12px; // Smaller font size
        color: #333;
        font-weight: bold;
      }
    }
  }
}
